<head>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta
    name="description"
    content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today."
  />
  <meta
    name="keywords"
    content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform"
  />
  <meta name="robots" content="index, follow" />
</head>
<!-- Google tag (gtag.js) -->
<script
  async
  src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"
></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-Y3D2LBP9KV");
</script>
<!-- Google tag (gtag.js) -->
<div class="container">
  <rentofly-loading *ngIf="loading"></rentofly-loading>
  <div style="text-align: center">
    <h4 *ngIf="!registrationComplete" class="partner-header">
      Unlock Your Potential
    </h4>
    <h4 *ngIf="registrationComplete" class="partner-header">Thank you</h4>
  </div>
  
  <div class="row">
    <div class="col-md-12 notice-div">
        <span>Register as a Partner and enhance your earnings</span>
      </div>
  </div>
  <div class="row"></div>
  <p-card class="partner-card add-property-card">
    <div class="row form-row">
      <div class="col-md-12 partner-parent-div" *ngIf="!registrationComplete">
        <form [formGroup]="partnerForm" class="partner-us-info">
          <div class="form-group partner-main-div">
            <span class="p-float-label partner-span partner-us-page">
              <input
                id="name"
                class="partner-input"
                type="text"
                formControlName="name"
                pInputText
              />
              <label for="name" class="input-label">Name</label>
            </span>
            <div
              *ngIf="name!.invalid && showPartnerValidationMessage"
              class="rentofly-login-validation-msg"
            >
              <div>Name is invalid.</div>
            </div>
          </div>

          <div class="form-group partner-div">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <span>+91</span>
              </span>
              <span class="p-float-label">
                <input
                  type="text"
                  id="phoneNumber"
                  class="partner-input"
                  pInputText
                  formControlName="phoneNumber"
                  onKeyPress="if(this.value.length==10) return false;"
                  OnlyNumber="true"
                  (blur)="validatePhone()"
                />
                <label for="phoneNumber" class="input-label">Mobile #</label>
              </span>
            </div>
            <div
              *ngIf="validatePhoneError"
              class="rentofly-login-validation-msg"
            >
              <div>
                {{ validatePhoneError }}
              </div>
            </div>
            <div
              *ngIf="phoneNumber!.invalid && showPartnerValidationMessage"
              class="rentofly-login-validation-msg"
            >
              <div>Phone # is invalid.</div>
            </div>
          </div>

          <div class="form-group partner-div">
            <span class="p-float-label partner-span partner-us-page">
              <input
                id="emailAddress"
                class="partner-input"
                type="text"
                formControlName="emailAddress"
                pInputText
              />
              <label for="emailAddress" class="input-label"
                >Email Address</label
              >
            </span>
            <div
              *ngIf="emailAddress!.invalid && showPartnerValidationMessage"
              class="rentofly-login-validation-msg"
            >
              <div>Email Address is invalid.</div>
            </div>
          </div>

          <div class="form-group partner-div">
            <span class="p-float-label partner-span partner-us-page">
              <input
                id="pinCode"
                class="partner-input"
                type="text"
                pKeyFilter="num"
                pattern="^[1-9][0-9]*$"
                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                maxlength="6"
                formControlName="pinCode"
                pInputText
              />
              <label for="pinCode" class="input-label">Pin Code</label>
            </span>
            <div
              *ngIf="pinCode!.invalid && showPartnerValidationMessage"
              class="rentofly-login-validation-msg"
            >
              <div>Pin Code is invalid.</div>
            </div>
          </div>
          <hr class="horizontal-line" />
          <div class="form-group partner-div id-text-div">
            <div class="id-text">
              Please fill at least one of the fields below for ID proof.
            </div>
            <br />
          </div>
          <div class="form-group partner-div">
            <span class="p-float-label partner-span partner-us-page">
              <input
                id="panNumber"
                class="partner-input"
                maxlength="10"
                type="text"
                formControlName="panNumber"
                pInputText
              />
              <label for="panNumber" class="input-label">Pan Number</label>
            </span>
            <div
              *ngIf="!(panNumber!.invalid && showPartnerPanValidationMessage)"
              class="maskedInformation"
            >
              <span>PAN # will be masked and not visible to others</span>
            </div>
            <div
              *ngIf="panNumber!.invalid && showPartnerPanValidationMessage"
              class="rentofly-login-validation-msg"
            >
              <div>Pan Number is invalid.</div>
            </div>
          </div>

          <div class="form-group partner-div">
            <span class="p-float-label partner-span partner-us-page">
              <input
                id="aadharNumber"
                pattern="^[1-9][0-9]*$"
                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                maxlength="12"
                class="partner-input"
                type="text"
                formControlName="aadharNumber"
                pInputText
              />
              <label for="aadharNumber" class="input-label"
                >Aadhar Number</label
              >
            </span>
            <div
              *ngIf="
                !(aadharNumber!.invalid && showPartnerAadharValidationMessage)
              "
              class="maskedInformation"
            >
              <span>Aadhar # will be masked and not visible to others</span>
            </div>
            <div
              *ngIf="
                aadharNumber!.invalid && showPartnerAadharValidationMessage
              "
              class="rentofly-login-validation-msg"
            >
              <div>Aadhar Number is invalid.</div>
            </div>
          </div>

          <div class="form-group partner-div">
            <span class="p-float-label partner-span partner-us-page">
              <input
                id="brokerRegNumber"
                class="partner-input"
                type="text"
                formControlName="brokerRegNumber"
                pInputText
              />
              <label for="brokerRegNumber" class="input-label"
                >Broker Reg Number</label
              >
            </span>
            <div
              *ngIf="
                brokerRegNumber!.invalid &&
                showPartnerBrokerRegValidationMessage
              "
              class="rentofly-login-validation-msg"
            >
              <div>Broker Reg Number is invalid.</div>
            </div>
          </div>
          <hr class="horizontal-line" />
          <div class="form-group partner-div">
            Please Click Upload Document and select a file to upload.
            <br /><br />
            <input
              #file
              type="file"
              id="attachment"
              (change)="onFileChange($event)"
              style="display: none"
              (click)="OnFileClick($event)"
            />

            <button
              type="button"
              for="attachment"
              class="upload-btn attachment-button"
              (click)="file.click()"
              [disabled]="currentFileUpload"
            >
              {{ currentFileUpload ? "" : "Upload Document" }}</button
            ><span>{{ currentFile?.name }}</span>
            <div *ngIf="errorMessage" class="rentofly-validation-msg">
              <div *ngIf="errorMessage">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <br />
          <hr class="horizontal-line" />
          <div class="action">
            <button
              class="rentofly-tertiary partner-confirm-btn"
              (click)="sendEmail(partnerForm.value)"
            >
              Register
            </button>
            <!-- <button class="rentofly-secondary">Cancel</button> -->
          </div>
        </form>
      </div>
      <div class="col-md-2">&nbsp;</div>
      <div class="col-md-8 partner-thankyou-div" *ngIf="registrationComplete">
        <p>Be on the lookout for an email from Rentofly.com</p>
        <p>
          We are currently in the process of reviewing your request and look
          forward to making this your best property management experience ever!
        </p>
        <p>Here's what you can expect next:</p>
        <p>
          We will send you an email once review is completed and approved by
          Rentofly admin team.
        </p>
        <p>
          Our Business Hours are Monday to Saturday 8 AM to 5 PM (Indian
          Standard Time).
        </p>
        <p>
          <b>IMPORTANT:</b> Be on the lookout for our email and please check
          your SPAM folder just in case.
        </p>
        <div class="action">
          <!-- <button class="rentofly-tertiary partner-confirm-btn" (click)="registerReset()">Home</button> -->
          <!-- <button class="rentofly-secondary">Cancel</button> -->
        </div>
      </div>
      <div class="col-md-2">&nbsp;</div>
    </div>
  </p-card>
</div>
