import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable()
export class BaseService{

    constructor(
        private readonly http: HttpClient,
        private readonly authService: AuthService
    ){}

    public baseCommandUrl(): string{ 
        return environment.rentoFlyCommand.baseUrl + "/" + environment.rentoFlyCommand.v1;
    }

    public baseQueryUrl(): string{ 
        return environment.rentoFlyQuery.baseUrl + "/" + environment.rentoFlyQuery.v1;
    }

    public baseApiUrl(): string{
        return environment.rentoFlyServices.baseUrl;
    }

    public baseFeedBackUrl(): string{
        return environment.rentoFlyServices.baseUrl + "/" + environment.rentoFlyServices.v1;
    }

    public baseServicesUrl(): string{
        return environment.rentoFlyServices.baseUrl;
    }

    public baseReferralCodeUrl(): string{
        return environment.rentoFlyServices.baseUrl;
    }

    public basePaymentUrl(): string{
        return environment.rentoFlyServices.baseUrl + "/" + environment.rentoFlyServices.v1;
    }

    public getHttpClient(): HttpClient{
        return this.http;
    }

    public getHeader(): HttpHeaders{
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.authService.getAccessToken()}`
          })
    }
}