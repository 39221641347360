<div class="container-fluid main-div">
  <div class="row home-page-row">
    <video class="video-background" autoplay muted loop >
      <source src="assets/GetInTouch_BG_L.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div class="col-md-12">
      <p class="header-banner-text">
        Be a member of <span class="span-underline">trustworthy</span
        ><br />rental ecosystem.
      </p>
      <p class="header-banner-sub-text">
        Broaden your perspectives and enhance your renting journey with us...
      </p>
      <div class="sticky-container" *ngIf="!isMobileBrowser()">
        <img src="/assets/images/APP QR.svg" alt="App QR Code" />
        <div class="text">
          <p>Download Rentofly</p>
        </div>
      </div>
      <form
        [formGroup]="loginForm"
        (keyup)="formRequest($event)"
        class="login-form"
      >
        <div class="login-div-section">
          <p *ngIf="isMobileBrowser()" class="downloadLink">
            <a (click)="downloadApp()">Download App</a>
          </p>
          <!-- <div
            *ngIf="loginMobileNo!.invalid && showPhoneValidationMessage"
            class="rentofly-login-validation-msg login-validation-msg"
          >
            <div>Mobile Number is invalid.</div>
          </div> -->
        </div>
        <!-- <div
          #loginCaptcha
          style="
            transform: scale(0.77);
            -webkit-transform: scale(0.77);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
          "
          data-theme="light"
          class="repcaptcha-container"
          ng-hide="true"
        ></div> -->
      </form>
    </div>
  </div>
  <div class="row home-page-row">
    <div class="col-md-12">
      <p class="ourValues">Our Values</p>
      <p class="crafting-para">
        Crafting an Exceptional Customer <br />Experience
      </p>
      <div class="values-cotainer">
        <div class="col-md-4 values-box values-img-1">
          <p class="value-box-header">Redefining Trust</p>
          <p class="value-box-para">
            Trust is the central focus and foundation of our platform.Our
            platform rewards members for their trustworthiness, making it an
            aspiration for all.
          </p>
        </div>
        <div class="col-md-4 values-box values-img-2">
          <p class="value-box-header">Money Matters</p>
          <p class="value-box-para">
            We specialize in providing comprehensive rental finance management
            solutions, helping you manage all aspects of your rental and home
            finances.
          </p>
        </div>
        <div class="col-md-4 values-box values-img-3">
          <p class="value-box-header">Prioritizing Privacy</p>
          <p class="value-box-para">
            At Rentofly, advanced encryption algorithms provide an impenetrable
            layer of protection, so you can rest assured that what's yours
            remains only yours.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row hidden home-page-row-2 rentofly-why-section">
    <div class="col-md-4 why-sec-1">
      <p class="why-para-1">Why should you choose us?</p>
      <p class="why-para-2">Rentofly at a glance</p>
      <img
        class="why-para-img"
        src="/assets/images/new-img/why-us.svg"
        width="400"
        height="400"
      />
    </div>
    <div class="col-md-8">
      <div class="why-sec-2-div">
        <i
          class="pi pi-arrow-right iconMobileView"
          *ngIf="!isMobileBrowser()"
        ></i>
        <p class="why-sec-2-para">
          Simplify the rental cycle, build trust among stakeholders with
          efficient tools.
        </p>
      </div>
      <div class="why-sec-2-div">
        <i
          class="pi pi-arrow-right iconMobileView"
          *ngIf="!isMobileBrowser()"
        ></i>
        <p class="why-sec-2-para">
          Streamline inviting, document collection, complaints, and task
          monitoring.
        </p>
      </div>
      <div class="why-sec-2-div">
        <i
          class="pi pi-arrow-right iconMobileView"
          *ngIf="!isMobileBrowser()"
        ></i>
        <p class="why-sec-2-para">
          Ensure standardized documentation, timely notifications, and fair
          contract closures.
        </p>
      </div>
      <div class="why-sec-2-div">
        <i
          class="pi pi-arrow-right iconMobileView"
          *ngIf="!isMobileBrowser()"
        ></i>
        <p class="why-sec-2-para">
          Provide convenient property services, manage rent collection, and
          simplify verification.
        </p>
      </div>
      <div class="why-sec-2-div">
        <i
          class="pi pi-arrow-right iconMobileView"
          *ngIf="!isMobileBrowser()"
        ></i>
        <p class="why-sec-2-para">
          Maintain property upkeep, clarity on deposits, and enhance tenant
          selection.
        </p>
      </div>
      <div class="why-sec-2-div">
        <i
          class="pi pi-arrow-right iconMobileView"
          *ngIf="!isMobileBrowser()"
        ></i>
        <p class="why-sec-2-para">
          Optimize investments with effective financial oversight for a seamless
          rental experience.
        </p>
      </div>
    </div>
  </div> -->
  <div class="row">
    <div class="col-md-12">
      <p class="why-para-2">Rentofly at a glance</p>
      <div class="banner-container hide">
        <img src="/assets/images/new-img/AppStore Banner 32.png" />
        <img src="/assets/images/new-img/AppStore Banner 33.png" />
        <img src="/assets/images/new-img/AppStore Banner 35.png" />
        <img src="/assets/images/new-img/AppStore Banner 36.png" />
        <img src="/assets/images/new-img/AppStore Banner 37.png" />
        <img src="/assets/images/new-img/AppStore Banner 34.png" />
        <img src="/assets/images/new-img/AppStore Banner 38.png" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p class="why-para-2">Why should you choose us?</p>
      <div class="why-choose-wrapper">
        <img class="graph" src="assets/images/Icon.svg" alt="Graph" />
        <div class="why-choose-container">
          <div class="why-sec-2-div">
            Simplify the rental cycle, build trust among stakeholders with
            efficient tools.
          </div>
          <div class="why-sec-2-div">
            Streamline inviting, document collection, complaints, and task
            monitoring.
          </div>
          <div class="why-sec-2-div">
            Ensure standardized documentation, timely notifications, and fair
            contract closures.
          </div>
          <div class="why-sec-2-div">
            Provide convenient property services, manage rent collection, and
            simplify verification.
          </div>
          <div class="why-sec-2-div">
            Maintain property upkeep, clarity on deposits, and enhance tenant
            selection.
          </div>
          <div class="why-sec-2-div">
            Optimize investments with effective financial oversight for a
            seamless rental experience.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p class="how-rentofly-sec-1">How it works?</p>
      <p class="how-rentofly-sec-2">It's as simple as that..</p>
      <div class="row">
        <div class="col-md-2 how-rentofly-num-sec">
          <img
            class="num-img num-img-1"
            src="/assets/images/new-img/01.svg"
            alt="01"
          />
          <img
            class="num-img num-img-2"
            src="/assets/images/new-img/02.svg"
            alt="02"
          />

          <img
            class="num-img num-img-3"
            src="/assets/images/new-img/03.svg"
            alt="03"
          />
          <img
            class="num-img num-img-4"
            src="/assets/images/new-img/04.svg"
            alt="04"
          />
          <img
            class="num-img num-img-5"
            src="/assets/images/new-img/05.svg"
            alt="05"
          />
          <img
            class="num-img num-img-6"
            src="/assets/images/new-img/06.svg"
            alt="06"
          />
        </div>
        <div class="col-md-10 content-mobile-view section-wrapper">
          <div class="content">
            <div class="linedown1"></div>
            <div class="linedown2"></div>
            <div class="linedown3"></div>
            <div class="linedown4"></div>
            <div class="linedown5"></div>
            <div class="linedown6"></div>
            <i class="spancls fa fa-dot-circle-o" aria-hidden="true"></i>
            <i class="spancls2 fa fa-dot-circle-o" aria-hidden="true"></i>
            <i class="spancls3 fa fa-dot-circle-o" aria-hidden="true"></i>
            <i class="spancls4 fa fa-dot-circle-o" aria-hidden="true"></i>
            <i class="spancls5 fa fa-dot-circle-o" aria-hidden="true"></i>
            <i class="spancls6 fa fa-dot-circle-o" aria-hidden="true"></i>
            <div class="how-it-works-div-1">
              <p class="how-it-works-header-1">Enter Property Details</p>
              <p>
                Add your property in less than a minute by filling out simple
                form with basic details.
              </p>
            </div>
            <div class="how-it-works-div-2">
              <p class="how-it-works-header-1">Add Stakeholders</p>
              <p>Add the details of Owner/ Broker/ Tenant.</p>
            </div>
            <div class="how-it-works-div-3">
              <p class="how-it-works-header-1">Approve Rental Deal</p>
              <p>
                Stakeholders will receive message notification to provide
                consent on the deal and they can Approve/ Reject the deal.
              </p>
            </div>
            <div class="how-it-works-div-4">
              <p class="how-it-works-header-1">Sign Agreement</p>
              <p>
                Download the drafted agreement and follow the recommended steps
                to complete the agreement process.
              </p>
            </div>
            <div class="how-it-works-div-5">
              <p class="how-it-works-header-1">Collect/ Pay Rent</p>
              <p>
                Upon mutual agreement, you can manage rent collection, track
                dues, send reminders, and simplify rent verification for timely
                payments.
              </p>
            </div>
            <div class="how-it-works-div-6">
              <p class="how-it-works-header-1">Manage Incidents & More</p>
              <p>
                Use the app to handle complaints, assign tasks, and monitor
                their progress efficiently. It also facilitates smooth and
                structured contract closures for a fair transition.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row other-service-mobile-view">
    <div class="col-md-12">
      <p class="other-services-header">
        <span class="other-services-sec-1">Other Services</span>
      </p>
      <p class="other-services-sec-2">There's more..</p>
      <div class="row other-services-carasoul">
        <div class="col-md-4 explore-sec">
          <p class="other-services-explore">
            Some of the other services offered are listed here but we have more
            to offer...
          </p>
        </div>
        <div class="col-md-8">
          <p-carousel
            [value]="features"
            [numVisible]="isMobileBrowser() ? '1' : '3'"
            [numScroll]="1"
            circular
            [responsiveOptions]="responsiveOptions"
            [autoplayInterval]="4000"
            *ngIf="isCarouselEnabled()"
          >
            <ng-template let-features pTemplate="item">
              <div>
                <img src="{{features.src}}" class="carusol-Img" />
              </div>
              <!-- <div class="carusol-img-text">
                                <h4 class="img-header">{{features.heading}}</h4>
                            </div> -->
            </ng-template>
          </p-carousel>
        </div>
      </div>
    </div>
  </div>

  <div class="row reserved-section-row">
    <div class="col-md-12">
      <p class="how-rentofly-sec-1">Get Access...</p>
      <p class="how-rentofly-sec-2">Ask for referral..</p>
    </div>
    <div class="col-md-12 reserved-section">
      <img src="../../assets/images/trust.svg" height="250px" />
      <div class="reserved-section-text-container">
        <div class="reserved-section-text">Exclusive for the trusted few.</div>
        <span class="trust-sub-text"
          >Trust, a virtue often overlooked. We believe it's time to give it the
          spotlight it deserves, especially for those who live by it. If you're
          among the trusted, download the app and request a referral code</span
        >
      </div>
    </div>
  </div>
  <div class="row join-partner-row">
    <div class="col-md-12 register-sec">
      <p class="join-partner-sec-1">want to join as Partner?</p>
      <p class="join-partner-sec-2">
        Partners can add their services to the platform and work within a
        reliable <br />community and earn great value for their help!
      </p>
      <div class="join-partner-sec-3">
        <a class="register-now-btn" routerLink="/add-partner">Register Now</a>
      </div>
    </div>
  </div>
</div>
<p-dialog
  [header]="popUpHeader"
  [(visible)]="disableOtp"
  modal="true"
  [closable]="true"
>
  <div *ngIf="showOtpSection">
    <p>Please enter the 6-Digit code sent to you at</p>
    <p class="dailog-phone-num">
      {{loginForm.controls.phonePrefix.value}}
      {{loginForm.controls.loginMobileNo.value}}
    </p>

    <input
      class="otp"
      [(ngModel)]="otpInput1"
      type="number"
      (oninput)="digitValidate(this)"
      (keyup)="tabChange(1)"
      min="0"
      maxlength="1"
    />
    <input
      class="otp"
      [(ngModel)]="otpInput2"
      type="number"
      (oninput)="digitValidate(this)"
      (keyup)="tabChange(2)"
      min="0"
      maxlength="1"
    />
    <input
      class="otp"
      [(ngModel)]="otpInput3"
      type="number"
      (oninput)="digitValidate(this)"
      (keyup)="tabChange(3)"
      min="0"
      maxlength="1"
    />
    <input
      class="otp"
      [(ngModel)]="otpInput4"
      type="number"
      (oninput)="digitValidate(this)"
      min="0"
      (keyup)="tabChange(4)"
      maxlength="1"
    />
    <input
      class="otp"
      [(ngModel)]="otpInput5"
      type="number"
      (oninput)="digitValidate(this)"
      min="0"
      (keyup)="tabChange(5)"
      maxlength="1"
    />
    <input
      class="otp"
      [(ngModel)]="otpInput6"
      type="number"
      (oninput)="digitValidate(this)"
      (keyup)="tabChange(6)"
      min="0"
      maxlength="1"
    />
  </div>
  <div *ngIf="showReferral">
    <p>
      Please enter the referral code to begin using
      <b class="dailog-phone-num">Rentofly</b>
    </p>
    <div class="referral-field">
      <span>Referral Code</span>
      <input
        type="text"
        [(ngModel)]="referralCode"
        placeholder="Enter referral code"
        class="referral-code-field"
      />
    </div>
    <div class="referral-info">
      <p>
        Don’t have a referral code? You can still use the rentofly
        <br />features by paying one time fees...
      </p>
      <button class="pay-btn" (click)="payMoneyToGetOtp()">Pay 63</button>
    </div>
  </div>
  <div class="p-field-radiobutton" *ngIf="showPaymentMode">
    <p-radioButton
      name="paymentMode"
      value="card"
      [(ngModel)]="paymentMode"
      inputId="paymentMode1"
    ></p-radioButton>
    <label class="payment-mode-label-1" for="paymentMode1"
      >Credit/Debit cards</label
    >
    <span class="payment-mode-span">
      <img class="payment-mode-icons" src="/assets/images/new-img/visa.png" />
      <img class="payment-mode-icons" src="/assets/images/new-img/card-2.png" />
      <img
        class="payment-mode-icons"
        src="/assets/images/new-img/master-card.png"
      /> </span
    ><br />
    <label class="payment-mode-label-2" for="paymentMode1"
      >Pay with your credit / Debit Card</label
    ><br />
    <div *ngIf="paymentMode == 'card'">
      <div class="p-inputgroup card-detail-div">
        <input type="text" pInputText placeholder="Card number" />
        <span class="p-inputgroup-addon"
          ><i class="pi pi-credit-card"></i
        ></span>
      </div>
      <div class="p-inputgroup cvv-section">
        <p-inputMask
          class="month-year-input"
          placeholder="MM/YY"
          [(ngModel)]="val"
          mask="99/99"
        ></p-inputMask>
        <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
      </div>
      <div class="p-inputgroup cvv-section">
        <input type="number" pInputText placeholder="CVV" />
        <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
      </div>
    </div>
  </div>
  <div class="p-field-radiobutton" *ngIf="showPaymentMode">
    <p-radioButton
      name="paymentMode"
      value="netBanking"
      [(ngModel)]="paymentMode"
      inputId="paymentMode2"
    ></p-radioButton>
    <label class="payment-mode-label-1" for="paymentMode2">Net Banking</label
    ><br />
    <label class="payment-mode-label-2" for="paymentMode2"
      >Make payment directly through bank account</label
    ><br />
    <div *ngIf="paymentMode == 'netBanking'">
      <p-dropdown
        class="bank-names"
        [options]="banks"
        [(ngModel)]="selectedBank"
        placeholder="Choose your bank"
        optionLabel="name"
        [showClear]="true"
        appendTo="body"
      ></p-dropdown>
      <div class="p-inputgroup card-detail-div">
        <input
          type="text"
          pInputText
          placeholder="Enter your bank account number"
        />
        <span class="p-inputgroup-addon"><i class="fa fa-university"></i></span>
      </div>
    </div>
  </div>
  <div class="p-field-radiobutton" *ngIf="showPaymentMode">
    <p-radioButton
      name="paymentMode"
      value="upi"
      [(ngModel)]="paymentMode"
      inputId="paymentMode3"
    ></p-radioButton>
    <label class="payment-mode-label-1" for="paymentMode3"
      >Other UPI Apps</label
    >
    <span class="payment-mode-span">
      <img class="payment-mode-icons" src="/assets/images/new-img/payPal.png" />
      <img
        class="payment-mode-icons"
        src="/assets/images/new-img/apple-pay.png"
      />
      <img class="payment-mode-icons" src="/assets/images/new-img/amazon.png" />
      <img class="payment-mode-icons" src="/assets/images/new-img/Gpay.png" />
    </span>
    <br />
    <label class="payment-mode-label-2" for="paymentMode3"
      >make through payment through Gpay, PayPal, Paytm etc</label
    >
    <div *ngIf="paymentMode == 'upi'">
      <div class="p-inputgroup card-detail-div">
        <input type="text" pInputText placeholder="Enter UPI ID" />
        <span class="p-inputgroup-addon"><i class="pi pi-id-card"></i></span>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button *ngIf="showReferral" type="button" class="dailog-verify-btn">
      Continue
    </button>
    <button *ngIf="showPaymentMode" type="button" class="dailog-verify-btn">
      Pay 63
    </button>
    <button
      *ngIf="showOtpSection"
      type="button"
      class="dailog-resend-btn"
      (click)="sendOtpRequest(true)"
    >
      Resend
    </button>
    <button
      *ngIf="showOtpSection"
      type="button"
      class="dailog-verify-btn"
      [disabled]="!otpInput1 || !otpInput2 || !otpInput3 || !otpInput4 || !otpInput5 || !otpInput6"
      (click)="verifyOtp()"
    >
      Verify
    </button>
  </ng-template>
</p-dialog>
